.maybeInterested {
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  font-weight: 600;
}
.AdvertismentFilms {
  padding-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .title {
    font-size: 24px;
  }
  .viewLink {
    font-size: 12px;
    .arrowLink {
      position: absolute;
      bottom: 0;
      right: 10%;
      display: flex;
      flex-direction: row;
      line-height: 8px;
      cursor: pointer;
      .view {
        display: inline-block;
        margin-right: 5px;
      }
      .straightArrow {
        display: inline-block;
        height: 8px;
      }
    }
  }
}
.bottomLine {
  background-color: black;
  opacity: 0.3;
  height: 1px;
  width: 100%;
  margin: 15px 0;
}
.AdvertismentFilms {
  @media (max-width: 450px) {
    .title {
      font-size: 0.9rem !important;
    }
  }
}

.AdvertismentFilms {
  @media (max-width: 800px) {
    .title {
      font-size: 1.3rem !important;
    }
  }
}

.dark-theme {
  .maybeInterested {
    color: whitesmoke;
  }
  .AdvertismentFilms {
    .title {
      color: whitesmoke;
    }
    .viewLink {
      color: whitesmoke;
      .straightArrow {
        fill: whitesmoke;
      }
    }
  }
}
