.about-section {
  transition: 0.3s ease-in-out;
  background-color: transparent;
  .about-banner {
    border-radius: 10px;
    width: 100%;
    margin: 1rem;
    
    }
}

.dark-theme {
  // background-color: #0e111a;
  .about-section {
    // background-color: #0e111a;
    transition: 0.3s ease-in-out;
    .about-banner {
      box-shadow: 0px 0px 16px -5px rgba(247,113,28,1);
    }

    .about-content {
      color: white;
  }
  }
}