.sponsors {
	padding: 20px 0;
	transition: ease-in-out 0.3s;
	background-color: transparent;

	.sponsor-box {
		position: relative;
		width: 100%;

		.sponsor-logo {
			padding: 5% 6%;
			height: 100%;
			width: 100%;

			color: white;
			display: flex;
			align-items: center;
			background-size: auto;
			background-position: center;
			background-repeat: no-repeat;

			filter: grayscale(1);

			transition: all 0.3s ease-in-out;
			img {
				width: 100%;
			}
			span {
				position: relative;
				left: -9999px;
			}

			&:hover {
				filter: none;
			}
		}
	}
}

.dark-theme {
	.sponsors {
		// background-color: #0e111a;
		.sponsor-box {
			.sponsor-logo {
				filter: grayscale(1) brightness(0) invert(1);

				&:hover {
					filter: none;
				}
			}
		}
	}
}
