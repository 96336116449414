.CrowdfundingComponent {


    .title {
        font-size: 2.5rem;
        font-weight: bold;
    }

    .sliderPhoto {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .informationContainer {


        .aboutPlatform {
            box-shadow: 0 0 16px -5px #f7711c;
            border-radius: 5px;
            background-color: rgba(255,255,255, 0.03);
            padding: 1rem;

            p {
                font-size: 0.9rem;
                margin-bottom: 0.1rem;
            }

            .aboutTitle {
                font-size: 1.8rem;
                color: #f7711c;
                font-weight: bold;
            }

            .aboutContent {
                font-weight: bold;
                font-size: 1.2rem;
            }

            .contactButtonContainer {
                justify-content: flex-end;
            }

            .contactButton {
                padding: 0.7rem 1rem;
                background-color: #f7711c;
                border-radius: 5px;
                color: white;
                font-weight: bold;
                font-size: 1.3rem;
                text-align: center;
                transition: ease-in-out .3s;

                &:hover {
                    text-decoration: none;
                    background-color: #c95812;
                }

            }


        }


        .orange-border {
            box-shadow: 0 0 16px -5px #f7711c;
            border-radius: 5px;
            background-color: rgba(255,255,255, 0.03);
            padding: 1rem;

            .infoNumber {
                font-size: 2.3rem;
                color: #f7711c;
                font-weight: bold;
                margin-right: 0.5rem;
            }

            .infoTitle {
                font-size: 1.4rem;
                font-weight: bold;
                margin-top: 0.7rem;
            }
        }
    }

    .projectContainer {
        box-shadow: 0 0 16px -5px #f7711c;
        border-radius: 5px;
        background-color: rgba(255,255,255, 0.03);
        text-decoration: none;
        color: black;

        &:hover {
            text-decoration: none;
        }
    

        .projectCategory {
            padding: 0rem 0.3rem;
            border-radius: 5px;
            justify-content: center;
            display: flex;
            align-items: center;
            height: 2rem;
            font-size: 0.9rem;

            &.education {
                background-color: green;
                color: white;
            }
            &.film {
                background-color: blue;
                color: white;
            }
            &.veteran {
                background-color: red;
                color: white;
            }
        }


        .orange-hover {
            position: absolute;
            display: none;
            background-color: #f7711c99;
            font-size: 1.8rem;
            font-weight: bold;
            top: 0.5rem;
            width: 97%;
            height: 94%;
            align-items: center;
            justify-content: center;
            color: white;
        }

        .projectInfoContainer{
            padding: 1rem;
            height: auto;


            .projectTitle {
                font-size: 1.8rem;
                font-weight: bold;
                color: #f7711c;
                
            }

            .progress {
                height: 0.6rem !important;
            }

            .progress-bar {
                background-color: #f7711c;
            }
        }

        // .project-film {
        //     border: 1px solid white;
        // }
        // .project-education {
        //     border: 1px solid rgb(36, 36, 204)
        // }
        // .project-veteran {
        //     border: 1px solid yellow
        // }
        
        .projectPreview {
            object-fit: cover;
            width: 100%;
            height: 200px;
        }

        .progressTitle, .gatheredMoney {
            color: #f7711c;
            font-weight: bold;
        }
    }

    .loadMoreContainer {
        margin-bottom: 3rem;
        margin-top: 1rem;
        justify-content: center;
        display: flex;
      }
    
      .loadMore {
        background-color: #f7711c;
        border-radius: 5px;
        padding: 5px 15px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        font-size: 24px;
        display: flex;
        align-items: center;
    
        .loadMoreCircle {
          font-size: 1.2rem;
          line-height: 1.3rem;
          height: 1rem;
          width: 1rem;
          color: white;
        }
    
        i {
          font-size: 1.2rem;
          line-height: 1.3rem;
          margin-right: 5px;
          color: white;
        }
    
        .loadMore_spinner {
          margin-right: 5px;
          display: inline-flex;
          justify-content: center;
          color: white;
    
          &>span {
            width: 1.3rem;
            height: 1.3rem;
          }
        }
    
        .loadMore_text {
          color: #fff;
          font-size: 1.2rem;
          font-weight: bold;
        }
      }


    //   .swiper-button-prev {
    //         position: absolute;
    //         z-index: 1000000;
    //         top:45%


    //   }
    //   .swiper-button-next {
            
    //         position: absolute;
    //         z-index: 1000000;
    //         top:45%;
    //         right: 0;
    //         font-family: "Font Awesome 5 Free";

            

    //   }


}

.dark-theme {

    .sliderContainer {
        color: white !important;
    }

    .informationContainer {
        color: white !important;
    }

    .projectTinyDescription {
        color: white !important;
    }

    .progressContainer {
        color: white !important;
    }

    .gatheredContainer {
        color: white !important;
    }
}





