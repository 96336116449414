.subscriptionModal {
  width: 100%;

  
  .subscription-container {
    width: 100%;
    margin: 0 auto;
    padding: 30px;
  }

  .slidecontainer {
    width: 100%;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 500px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 10px;
    border-radius: 500px;
    background: rgb(255, 123, 0);
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
  }

  .btn {
    background-color: rgb(252, 122, 1);
  }

  .subscription-header {
    width: 100%;
  }
}