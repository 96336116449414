.SearchPopUp {
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 51;
	color: white;
	padding: 1.75rem;
	overflow-y: auto;
	.GenresList {
		background-color: transparent !important;
	}
	.SearchTitle {
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 1rem;
		margin-top: 2rem;
	}

	.moviesContainer {
		display: flex;
		font-display: row;
	}

	.cast-box {
		width: 100%;
		padding-top: 150%;
		position: relative;

		cursor: pointer;
		display: block;
		margin-bottom: 0.3rem;

		border-radius: 5px;
		.cast-content {
			position: absolute;
			padding: 15px 0;
			height: 100%;
			width: 100%;
			right: 0;
			top: 0;

			color: white;

			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			background-color: #0e111a;

			border: 0;
			box-shadow: 0 0 3px #0e111a;

			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-radius: 5px;

			transition: 0.3s ease-in-out;
			* {
				z-index: 10;
			}

			&:before {
				content: ' ';
				display: block;
				position: absolute;
				height: 100%;
				width: 100%;
				left: 0;
				top: 0;

				border-radius: 5px;
				background: linear-gradient(180deg, rgba(24, 24, 24, 0.55) 0%, #171717 100%);
				opacity: 0.5;
				z-index: 5;

				transition: all 0.3s ease-in-out;
			}
		}
	}
	.cast-title {
		font-size: 0.8rem;
		font-weight: bold;
		margin-bottom: 0;
		line-height: 1rem;
		transition: 0.3s ease-in-out;
	}

	.cast-position {
		line-height: 1rem;
		font-size: 0.9rem;
		transition: 0.3s ease-in-out;
	}

	.genre-card {
		width: 100%;
		padding-top: 70%;
		position: relative;

		cursor: pointer;

		cursor: pointer;

		.card {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 100%;

			background-color: #0e111a;

			padding: 16px 8px;
			text-align: center;
			font-weight: bold;

			color: white;

			transition: 0.3s ease-in-out;

			svg {
				display: inline-block;
				fill: white;
				height: 100%;
				transition: 0.3s ease-in-out;
			}

			&:hover {
				text-decoration: none;
				color: #f7711c;
				svg {
					fill: #f7711c;
					transition: 0.3s ease-in-out;
				}
			}
		}
	}

	.searchHeader {
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
		.searchTitle {
			font-size: 1.5rem;
			margin-left: 2rem;
			font-weight: bold;
		}
		.closeCross {
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			font-size: 1.5rem;

			i {
				margin-right: 1.5rem;
				// margin-top: 1rem;
			}
		}
	}

	.searchTrans {
		// transform: translate(-48%, -680%) !important;
		top: 0 !important;
	}

	.searchField {
		margin-top: 1rem;
		position: relative;
		top: 40%;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 70vw;
		height: 2.5rem;
		padding-left: 1rem;
		transition: all 0.5s ease-in-out;
	}
}

.search-enter {
	opacity: 0;
}
.search-enter-active {
	opacity: 1;
	transition: 0.3s ease-in-out;
}
.search-exit {
	opacity: 1;
}
.search-exit-active {
	opacity: 0;
	transition: 0.3s ease-in-out;
}
