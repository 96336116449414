.CrowdfundingDetailComponent {

    .modal {
        .promoInput {
            border: none;
            border-bottom: 1.5px solid grey;
            background-color: transparent;
            color: white;
            width: 100%;
        }

    }



    #sticky-sidebar {
        position: -webkit-sticky;
        position: sticky;
	    top: 5rem;
    }

    .filmTitle {
        font-size: 2.5rem;
        color: #f7711c;
        font-weight: bold;
        height: fit-content;

        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }

    .filmContent {

        margin-top: 2rem;

        p {
            font-size: 1.1rem;
        }

        @media (max-width: 768px) {

            margin-top: 1rem;

            p {
                font-size: 1rem;
            }
        }
        
    }

    .infoBannerContainer {
        padding: 1rem;
        border: 1px solid #f7711c;
        border-radius: 5px;
        background-color: rgba(255,255,255,0.03);

        .projectCategory {
            padding: 0rem 0.3rem;
            border-radius: 5px;
            justify-content: center;
            display: flex;
            align-items: center;
            height: 2rem;
            font-size: 0.9rem;

            &.education {
                background-color: green;
                color: white;
            }
            &.film {
                background-color: blue;
                color: white;
            }
            &.veteran {
                background-color: red;
                color: white;
            }
        }

        .filmTitleContainer {
            font-size: 2rem;
            font-weight: bold;
            color: #f7711c;
        }

        .filmTinyDescription {
            font-size: 1.1rem;

            @media(max-width: 768px) {
                font-size: 1rem;
            }
        }

        .supportButton {
            background-color: #f7711c;
            border-radius: 5px;
            color: white;
            font-weight: bold;
            cursor: pointer;
            transition: ease-in-out .3s;
            width: 100%;
            box-shadow: none;
            border: none;

            &:hover {
                background-color: #c4540e;
            }
        }

        .aboutMoneySection {

            margin: 1.5rem 0;

            .gatheredMoney {
                font-size: 2.2rem;
                font-weight: bold;

                span {
                    color: #f7711c;
                }
            }

            .statisticSection {
                font-size: 0.8rem;
            }

            .numberStaticContainer {

                @media(max-width:768px) {
                    margin-top: 0.3rem !important;
                    margin-bottom: 0.3rem !important;
                }
            }

            .progress {
                height: 0.6rem !important;
            }

            .progress-bar {
                background-color: #f7711c;
            }

            .supportCountTitle, .daysLeftTitle, .beginningDateTitle {
                font-size: 0.8rem;
                color: rgb(165, 165, 165);
            }

            .gatheredMoneyColumn {
                color: rgb(165, 165, 165);
            }

            @media (max-width:768px) {
                .statisticSection {
                    font-size: 0.7rem;
                }
    
                .supportCountTitle, .daysLeftTitle, .beginningDateTitle {
                    font-size: 0.6rem;
                }
                .supportCountNumber, .daysLeftNumber, .beginningDateNumber {
                    font-size: 0.8rem;
                }
            }

            .listTitle {
                font-weight: bold;
                font-size: 1.2rem;
            }
        }

       
    }

    .docsLink {
        font-size: 1.2rem;
        text-decoration: underline;
        color: black;
        transition: .3s ease-in-out;

        &:hover {
            color: rgb(87, 87, 87);
        }
    }

    .AboutPerson {

        #sticky-photo {
            margin-bottom: 1rem;
        }

        @media (min-width: 768px) {

            
            #sticky-photo {
                position: sticky;
                top: 5rem;
                height: fit-content;
            }
        }

        .aboutPersonTitle {
            font-size: 2.5rem;
            color: #f7711c;
            font-weight: bold;
            line-height: 2.5rem;
        }


        .aboutPersonSubTitle {
            font-size: 1.5rem;
            color: rgb(115, 115, 115);
            font-style: italic;
            margin-bottom: 1rem;
        }

        .photo {

            .photoContainer {

                .image {
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
        .aboutContent {
            font-size: 1.1rem;
        }
    }

    .promoModal {
        z-index: 10000;
    
        .promoInput {
            border: none;
            border-bottom: 1.5px solid grey;
            background-color: transparent;
            color: white;
        }
        .promoModal-header {
            background-color: #171c32;
            color: white;
            border-bottom: none !important;
    
            .close {
                color: white !important;
            }
        }
        .promoModal-body {
            background-color: #171c32;
            color: white;
        }
    }

    .hot-movie-banner {
        position: relative;
        padding-top: 40%;
        width: 100%;
        
        color: white;
        transition: .3s ease-in-out;
        .hot-movie {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
    
            z-index: 1;
    
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
    
            * {
                z-index: 10;
            }
    
            &:before {
                content: " ";
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                
                border-radius: 5px;
                background: linear-gradient(180deg, rgba(24, 24, 24, 0.35) 0%, #171717 150%);
                z-index: 5;
            }
    
            .meta {
                width: 100%;
                padding: 2.5% 4%;
                .title {
                
                    font-size: 2rem;
                    font-weight: bold;
                }
        
                .actions {
    
                    .watch-trailer {
                        color: white;
                        font-weight: bold;
                        background: transparent;
                        border: none;
                        outline: none;
    
                        transition: .3s ease-in-out;
                        &:hover {
                            text-decoration: none;
                            color: #f7711c;;
                        }
                    }
                    
                    .play-button {
                        color: white;
                        padding: 12px 35px;
                        background-color: #f7711c;
                        border-radius: 5px;
    
                        transition: .3s ease-in-out;
    
                        span {
                            font-weight: bold;
                        }
                        svg {
                            fill: white;
                            height: 1.5rem;
    
                            transition: .3s ease-in-out
                        }
    
                        &:hover {
                            text-decoration: none;
                            color:  #0e111a;
    
                            svg {
                                fill: #0e111a;
                            }
                        }
                    }
                }
            }
    
            @media(max-width: 576px) {
                
    
                .meta {
                    padding: 2.5% 4%;
    
                    .title {
                        font-size: 1rem;
                        line-height: 1rem;
                    }
    
                    .actions {
                        
                        .play-button {
                            font-size: .8rem;
                            padding: 5px 10px;
    
                        }
                    }
                
                }
            }
        }
    
        .hot-movie {
            .jwplayer {
                border-radius: 5px;
                
                .jw-aspect.jw-reset {
                    padding-top: 40% !important;
                }
        
                &.jw-stretch-uniform .jw-media video {
                    object-fit: cover !important;
                    padding: 10px;
                    background-color: #0e111a;
                
                }
            }
        }
    }

    .donateTitle {
        font-size: 2rem;
        font-weight: bold;
    }

    .DonatorsComponent {
        
        .titleContainer {
            font-size: 1.2rem;
            font-weight: bold;
        }

        .contentContainer {
            color: rgb(100, 100, 100);
        }
    }
}

.dark-theme {
    .filmTinyDescription {
        color: white !important;
    }
    .aboutMoneySection {
        color: white !important;
    }

    .docsLink {
        color: whitesmoke;

        &:hover {
            color: rgb(155, 155, 155);
        }
    }

    .DonatorsComponent {
        color: white;

        .contentContainer {
            color: rgb(180, 180, 180);
        }
    }

    .donateTitle {
        color: white;
    }

    .hot-movie-banner {
        box-shadow: 0px 0px 16px -5px rgba(247,113,28,1);
        border-radius: 5px;        
    }
}