.BreadCrumb {
  padding: .5rem 0;
  color: #0e111a;
  font-weight: bold;
  background-color: transparent;

  transition: .3s ease-in-out;
  ul {
    font-size: 0.9rem;
    list-style: none;
    padding: 0.6rem 0;
    margin-bottom: 0;

    li {
      display: inline-block;

      a {
        color: rgb(173, 173, 173);
        transition: .3s ease-in-out;

        &:hover {
          text-decoration: none;
          color: #f7711c;
        }
      }

      &:after {
        content: "/";
        color: #0e111ab3;
        display: inline-block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
      
    }
  }
}

.dark-theme {
  .BreadCrumb {
    // background-color: #0e111a;
    color: white;

    ul {


      li {

        &:after {
          color: #394569b3;
        }
      }
    }
  }
}