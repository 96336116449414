.movie-details {
    background: transparent;

    transition: .3s ease-in-out;
}
.movie-header {

    color: #0e111a;

    .title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 2rem;
        padding-bottom: .5rem;
        display: flex;

        color: #0e111a;
        transition: .3s ease-in-out;
        span {
            margin-left: 1rem;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: inherit;
        }
    }

    .age{
        font-size: 1.2rem;
        padding: 4px 8px;
        font-weight: bold;
        background-color: #f7711c;
        border-radius: 5px;
        margin-left: 1rem;
        display: inline-block;
        transition: 0.3s ease-in-out;
    }

    .genre-list {
        list-style: none;
        margin: 0;
        padding: 0;
        .genre-item {
            display: inline-block;

            a {
                display: inline-block;
                color: white;
                font-size: .8rem;
                font-weight: bold;
                padding: 5px 10px;
                border-radius: 50px;
                background: #0e101ad6;
                margin-right: .5rem;
                margin-bottom: .5rem;

                transition: .3s ease-in-out;

                &:hover {
                    color: #f7711c;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 837px) {
        .title {
            font-size: 1.5rem;
        }
        .age{
            font-size: 0.8rem;
            margin-left: 0rem;
        }
    }
}


.dark-theme {
    .movie-details {
        background-color: #0e111a;
        .movie-header {
            color: white;
            .title {
                color: white;
            }
            
            .genre-list {
                .genre-item {
                    a {
                        background: #252a46d6;
                    }
                }
            }
        }
    } 
}