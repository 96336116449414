.blogDetails {
	a:hover {
		text-decoration: none;
	}

	.blogCategories {
		.btn,
		.btn-link {
			&:hover {
				text-decoration: none !important;
				box-shadow: none !important;
			}
			&:active {
				outline: none !important;
				box-shadow: none !important;
			}
			&:focus {
				outline: none !important;
				box-shadow: none !important;
				text-decoration: none;
			}
		}
		.card {
			position: relative;
			border: 1px solid lightgray;
			border-radius: 10px;
			font-family: Nunito;
			.card-header {
				border-bottom: none;
				background-color: transparent;
			}
			&:hover {
				box-shadow: none;
			}
			.card-body {
				li {
					list-style: none;
					font-weight: 600;
					padding: 8px 0px;
					a {
						font-weight: 600 !important;
						color: black;

						transition: 0.3s ease-in-out;
						&:hover {
							color: #ff8231;
						}
					}
				}
				& > a {
					font-weight: 600 !important;
					color: black;
					&:hover {
						color: #ff8231;
					}
				}
			}
			button {
				i,
				span {
					color: black;
					font-size: 18px;
					text-decoration: none;
				}
				span {
					font-weight: 600;
					margin-left: 10px;
				}
			}
		}
	}

	.tags {
		border-radius: 10px;
		padding: 20px;
		border: 1px solid lightgray;
		.tagsTitle {
			font-weight: 600;
		}

		.d-inline-block {
			padding: 5px;
			&:hover {
				cursor: pointer;
				background-color: #ff771f;
			}
			&:hover a {
				color: white;
			}
		}
	}

	// Blog
	.blogImg {
		border-radius: 10px;
	}
	.blogTitle {
		font-family: Nunito;
		font-size: 24px;
		font-weight: 700;
		color: #222222;
	}

	.blogBody {
		font-family: Nunito;
		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: normal;
		line-height: 32px;
		color: #798795;
	}

	// Blog Quotes

	.blogQuotes {
		border: 2px solid #ff771f;
		padding: 80px 80px 80px 50px;
		border-radius: 5px;
		position: relative;
		@media (max-width: 575px) {
			padding: 40px 40px 40px 25px;
		}

		span {
			color: #000000;
			font-family: Nunito;
			font-size: 32px;
			font-weight: 100;

			letter-spacing: normal;
			line-height: 48px;

			@media (max-width: 767px) {
				font-size: 22px;
			}
			@media (max-width: 575px) {
				font-size: 18px;
			}
		}
		.hangouts {
			position: absolute;
			top: 15px;
			right: 30px;
			@media (max-width: 575px) {
				right: 10px;
			}
		}

		.quotesOpen {
			font-family: Poppins;
			font-size: 72px;
			font-weight: 300;
			color: #ff771f;
		}
		.quotesClosed {
			display: block;
			transform: rotate(180deg) translate(5px, 30px);
			font-family: Poppins;
			font-size: 72px;
			font-weight: 300;
			color: #ff771f;
		}
		// next is end of quotes
	}

	//Socials
	.soc {
		i {
			font-size: 18px;
			padding-left: 5px;
			padding-right: 5px;
		}
		.facebook {
			color: #45619d;
			font-size: 16px;
		}
		.googlePlus {
			color: #cf4539;
		}
		.twitter {
			color: #42c0fb;
		}
		.skype {
			color: #009ee5;
		}
		.rss {
			color: #fd9f13;
		}
	}
}

.dark-theme {
	.blogTitle {
		color: white;
	}

	.blogBody {
		color: rgb(220, 220, 220);
	}

	.blogQuotes {
		.quotesContent {
			color: whitesmoke;
		}
	}

	.soc {
		color: whitesmoke;
	}
}
