.ContactUsAdress {

    .map {
        height: 35rem;
        width: 100%;
    }
}

    // @media (max-width: 991px) {
    //     .ContactUsAdress {
    //         .map {
    //             height: 20rem;
    //             width: 100%;
    //         }
    //     }
    // }

@media (min-width: 992px) {
    .ContactUsAdress {
        .map {
            height: 480px;
            width: 100%;
        }
    }
}


@media (min-width: 1200px) {
    .ContactUsAdress {
        .map {
            height: 460px;
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .ContactUsAdress {
        .map {
            height: 20rem;
            width: 100%;
        }
    }
}
