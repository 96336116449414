body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	width: 100vw;
	/* overflow-x: hidden; */
}
html {
	overflow-x: hidden;
	width: 100vw;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
* {
	font-family: 'Nunito', sans-serif;
}
*:focus {
	outline: none !important;
}
.nonScroll {
	overflow: hidden;
	height: 100vh;
}

#root {
	position: relative;
	padding-top: 64.8px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.slider .slider-slide {
	justify-content: center;
	display: inline-flex !important;
}

.paging-item button,
.paging-item button:focus {
	outline: none;
}

.slider-slide,
.slider-slide:focus-visible,
.slider-slide:focus {
	outline: none;
}
