.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-float infinite 3s ease-in-out;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(0px);
	}
}

/* Custom Gadimlie */

@media (min-width: 992px) {
	div.container-fluid {
		padding-left: 6rem !important;
		padding-right: 6rem !important;
	}
}

.col-by {
	padding-right: 8px !important;
	padding-left: 8px !important;
	margin-bottom: 16px;
}

.col-by.col {
	flex-basis: auto !important;
}

@media (min-width: 350px) {
	.col-by-2 {
		flex: 0 0 50%;
		max-width: 50% !important;
	}
}

@media (min-width: 512px) {
	.col-by-3 {
		flex: 0 0 33.333333333%;
		max-width: 33.333333333% !important;
	}
}

@media (min-width: 744px) {
	.col-by-4 {
		flex: 0 0 25%;
		max-width: 25% !important;
	}
}

@media (min-width: 920px) {
	.col-by-5 {
		flex: 0 0 20%;
		max-width: 20% !important;
	}
}

@media (min-width: 1096px) {
	.col-by-6 {
		flex: 0 0 16.666666%;
		max-width: 16.666666% !important;
	}
}

@media (min-width: 1272px) {
	.col-by-7 {
		flex: 0 0 14.285714285714285714285714285714%;
		max-width: 14.285714285714285714285714285714% !important;
	}
}

.swiper-container {
	padding-right: 4rem !important;
}

@media (min-width: 576px) {
	.text-left-sm {
		text-align-last: left;
	}
}

.site-loading {
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10000000;
	background-color: rgba(0, 0, 0, 0.8);
}

.site-loading .site-loading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.site-loading .site-loading-spinner .spinner-border {
	border: 0.25em solid #f7711c;
	border-right-color: transparent;
}

.custom_spinner {
	margin-right: 5px;
	display: inline-flex;
	justify-content: center;
	color: white;
}
.custom_spinner_2 {
	margin-right: 5px;
	display: inline-flex;
	justify-content: center;
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	transform: scale(3);
}

.custom_spinner .spinner_circle {
	font-size: 1.2rem;
	line-height: 1.3rem;
	height: 1rem;
	width: 1rem;
	color: white;
}

[data-f-id] {
	display: none;
}

.Toastify__toast--success {
	background-color: #f7711c !important;
}

.Toastify__toast--error {
	background-color: #f70d1c !important;
}

.Toastify__toast--info {
	background-color: #0e111a !important;
}

.dark-theme .Toastify__toast--info {
	background-color: white !important;
	color: black !important;
	/* box-shadow: 0px 0px 16px -5px rgba(247,113,28,1); */
}

.dark-theme .Toastify__progress-bar--info {
	background-color: #000000b3 !important;
	/* box-shadow: 0px 0px 16px -5px rgba(247,113,28,1); */
}

.btn-success {
	background-color: #f7711c !important;
	border-color: #f7711c !important;
}

.btn-success:focus {
	box-shadow: 0 0 0 0.2rem #f7701c7d !important;
}

.subscriptionSectionLoading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	background-color: #000000cb;
}

.subscriptionSectionLoading .subscriptionSectionLoadingSpinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.subscriptionSectionLoading .subscriptionSectionLoadingSpinner .spinner-border {
	border: 0.25em solid #f7711c;
	border-right-color: transparent;
}
