.FollowUs {
    border-radius: 10px;
    border: 1px solid lightgrey;
    padding: 1rem;
    transition: ease-in-out 0.3s;
    margin-bottom: 3rem;

    .title {
        font-size: 1.2rem;
        font-weight: 600;
    }


    .socialContainer{
        display: flex;
        margin-top: 0.8rem;
        .social {
            margin-right: .5rem;
            margin-bottom: .5rem;
            display: flex;
            border-radius: 50%;
            border: 1px solid rgb(187, 187, 187);
            height: 40px;
            width: 40px;
            cursor: pointer;
            transition: ease-in-out 0.3s;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 0.8rem;
            color: black;
            text-decoration: none;

            i {
                transition: ease-in-out 0.3s;
            }

            &:hover {
                background-color: lightgrey;

                i{
                    color: #f7711c;
                }
            }
        }
    }
}

.dark-theme {
    .FollowUs {
        .title {
            color: whitesmoke;
        }

        .social {
            color: whitesmoke;

            &:hover {
                background-color: lightgrey;
            }
        }
    }

}