.NotFoundComponent {
	width: 100%;
	height: 20rem;

	.backGround {
		height: 100%;
		display: flex;
		padding-top: 4rem;
		padding-bottom: 4rem;
		.notFoundText {
			padding-top: 5rem;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
      align-items: center;

			.number {
				font-size: 5rem;
				font-weight: bold;
				line-height: 3rem;
				align-items: center;
				justify-content: center;
				text-align: center;
        margin-bottom: 2rem;
			}

			.pageNotFound {
				font-size: 3rem;
				text-align: center;
        margin-bottom: 2rem;
			}

			.returnBackButton {
				width: 250px;
				font-weight: 800;
				background-color: #f7711c;
				color: white;
				border: none;
				border-radius: 5px;
				outline: none;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-size: 1.5rem;
				&:hover {
					background-color: #cb5c16;
				}
				.leftArrow {
					transform: rotate(180deg);
					height: 0.4rem;
					margin-right: 0.3rem;
					fill: white;
				}
			}
		}
	}
}

.dark-theme {
	.NotFoundComponent {
		color: whitesmoke;
	}
}
