.movie-description {
	padding: 20px 6% 0;
	position: relative;
	margin-bottom: -5%;

	color: #0e111a;

	transition: 0.3s ease-in-out;
	.movie-poster {
		position: relative;
		transform: translate(0, -50%);
		width: 100%;
		padding-top: 150%;
		margin-bottom: -50%;
		z-index: 10;

		@media (max-width: 375px) {
			width: 136%;
			padding-top: 207%;
		}

		.poster-content {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;

			background-color: #0e111a;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			border-radius: 5px;
			box-shadow: 0px 0px 16px -5px rgba(247, 113, 28, 1);
			&:after {
				content: ' ';
				display: block;
				position: absolute;
				height: 100%;
				width: 100%;
				left: 0;
				top: 0;
				border-radius: 5px;
				background: linear-gradient(180deg, rgba(24, 24, 24, 0.55) 0%, #171717 100%);
				opacity: 0.5;
				z-index: 5;
				transition: all 0.3s ease-in-out;
			}
		}
	}

	.meta {
		div {
			color: black;
			font-size: 1.2rem;
			padding: 4px 8px;
			font-weight: bold;

			transition: 0.3s ease-in-out;
			.movieYear {
				color: #f7711c;
				margin-left: 0.5rem;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				&:hover {
					text-decoration: none;
				}
			}
		}

		.time {
			float: right;
			color: #f7711c;
		}

		.country {
			float: left;
		}

		.year {
			float: left;
		}
	}

	.clearfix {
		content: '';
		clear: both;

		.country {
			.countryName {
				margin-left: 0.5rem;
				transition: all 0.3s ease-in-out;
				cursor: pointer;
				color: #f7711c;
				&:hover {
					color: #f7711c;
				}
			}
		}

		@media (max-width: 725px) {
			.country {
				font-size: 1rem;
			}
			.year {
				font-size: 1rem;
			}
			.time {
				font-size: 1rem;
			}
		}
	}

	.description {
		padding: 8px;

		.show-more-button {
			display: flex;
			font-weight: bold;
			color: #f7711c;

			&:hover {
				text-decoration: none;
			}

			&:after {
				content: '\25be';
				color: #0e111a;
				font-size: 1.3rem;
				line-height: 1.3rem;
				margin: 0 5px;
				display: inline-block;

				transition: 0.3s ease-in-out;
			}
		}

		&.expanded {
			.show-more-button {
				&:after {
					transform: rotate(-180deg);
				}
			}
		}
	}
}

.dark-theme {
	transition: 0.3s ease-in-out;
	.movie-description {
		color: white;

		.meta {
			div {
				color: white;
			}

			.time {
				color: #f7711c;
			}
		}

		.description {
			.show-more-button {
				&:after {
					color: white;
				}
			}
		}
	}
}


@media(max-width: 768px) {
	.movie-poster-column {
		display: none;
	}
	 @media (max-width: 425px) {
		 .clearfix {
			.country {
				font-size: 0.9rem !important;
			}
			.year {
				font-size: 0.9rem !important;
			}
			.time {
				font-size: 0.9rem !important;
				float: left !important;
			}
		 }
		 .description {
			 font-size: 0.8rem;
		 }
	 }
}