.LanguageSwitcher {
	.dropdown-toggle {
		color: black !important;
	}
}

.dark-theme {
	.LanguageSwitcher {
		.dropdown-toggle {
			color: whitesmoke !important;
		}
	}
}
