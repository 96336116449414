.account {
	transition: 0.3s ease-in-out;

	@media (max-width: 566px) {
		.redact {
			font-size: 0.8rem !important;
		}

		.fa-sign-out-alt {
			font-size: 0.8rem !important;
			line-height: 1.2rem !important;
		}

		.fa-pencil-alt {
			font-size: 0.8rem !important;
			line-height: 0.8rem !important;
		}
	}

	@media (max-width: 380px) {
		.redact {
			display: none;
		}
	}

	@media (max-width: 450px) {
		.subscription {
			font-size: 1rem !important;
		}

		.activate {
			font-size: 0.8rem !important;
		}
	}
  


	.title {
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 1.5rem;
	}
	.profileNotif {
		transition: 0.3s ease-in-out;

		i {
			color: #f7711c;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.07);
		}
	}
	.profileSertif {
		transition: 0.3s ease-in-out;

		i {
			color: #f7711c;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.07);
		}
	}

	.loginBtn {
		margin-top: 1rem;
		background-color: #f7711c;
		border-radius: 5px;
		border: none;
		height: 2.5rem;
		font-size: 1rem;
		font-weight: bold;
		color: whitesmoke;
		transition: 0.3s ease-in-out;
		justify-content: center;
		align-items: center;

		&:focus,
		:active {
			outline: 0;
			outline-offset: 0;
		}

		&:hover {
			background-color: #c75b18;
		}
	}
	a {
		text-decoration: none;

		&:hover {
			text-decoration: none !important;
			// color: #ff771f !important;
			// border-bottom: #ff771f !important;
		}
	}

	.profileHeader {
		// height: 223px;
		// background-image: url("../../assets/profilewallpaper3.jpg");
		background-size: cover;
		color: white;
		top: 10px;

		.profileNameChange {
			font-size: 1.5rem;
			font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
			font-weight: bold;
			color: black;
			border: none;
			background-color: transparent;
			line-height: 1.5rem !important;
			border-radius: 5px;
			box-shadow: 0 0 0.2rem rgb(7, 107, 7);
			transition: ease-in-out 0.3s;
			padding: 0.5rem;
			&.wrongEmail {
				box-shadow: 0 0 0.2rem #f70d1c;
			}

			@media (max-width: 566px) {
				font-size: 1rem;
			}
		}

		.profileName {
			font-size: 1.5rem;
			font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
			font-weight: bold;
			color: black;
			line-height: 1.5rem;

			@media (max-width: 566px) {
				font-size: 1rem;
			}
		}

		.fa-pencil-alt {
			color: black;
		}

		.profileMail {
			font-size: 1.2rem;
			color: black;
		}

		.container {
			height: 100%;
			position: relative;
		}

		.userName {
			width: 70px;
			height: 70px;
		}
	}

	.movies-list {
		.empty {
			color: black;
			transition: 0.3s ease-in-out;
		}
	}

	.pmc {
		color: black !important;
		font-size: 22px;
		font-weight: 500;
		border-bottom: 1px solid gray;

		@media (max-width: 768px) {
			font-size: 18px;
		}
	}

	.active {
		color: #ff884d !important;
		border-bottom: 1px solid #ff884d !important;
	}
	.subscription {
		font-size: 29px;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
	}
	.subscriptionStatus {
		font-size: 19px;
		font-weight: 600;
		color: #f7711c;
		display: flex;
		justify-content: space-between;
	}

	.profileSection1 {
		.notifSert {
			padding: 10px !important;

			.notifications {
				border: 1px solid #ededed;
				border-radius: 10px;
				padding: 20px;
				height: 100%;
			}

			.serftificate {
				border: 1px solid #ededed;
				border-radius: 10px;
				padding: 20px;
				height: 100%;
			}
		}

		.btn-secondary {
			&.dropdown-toggle {
				box-shadow: none;
				background-color: transparent;
				border: none;
				&:focus {
					box-shadow: none;
				}
			}
			background-color: transparent;
			border: none;

			&::after {
				content: none;
			}

			&:hover {
				background-color: transparent;
				border: none;
			}

			&:focus {
				border: none;
				background-color: transparent;
				box-shadow: none;
			}

			&:active {
				&:focus {
					&.dropdown-toggle {
						background-color: transparent;
						box-shadow: none;
						border: none;
					}
				}

				&.dropdown-toggle {
					background-color: transparent;
					box-shadow: none;
					border: none;
				}
				border: none;
				background-color: transparent;
				box-shadow: none;
			}
		}

		.profileTop {
			padding: 10px;
		}

		.settingLinks {
			padding: 10px;
			height: 10rem;

			@media (min-width: 992px) {
				flex: 0 0 20%;
				max-width: 20%;
			}
			.profileSettings {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				text-align: center;
				transition: 0.3s ease-in-out;
				&:hover {
					background-color: rgba(0, 0, 0, 0.07);
				}

				i {
					font-size: 1.5rem;
					color: #f7711c;
				}
			}
		}

		.profileTopInner {
			border: 1px solid #ededed;
			border-radius: 10px;
			padding: 10px 20px;
			height: 100%;
			flex-wrap: wrap;
		}

		.lorem {
			font-size: 16px;
			opacity: 0.7;
			font-weight: 600;
		}

		.activate {
			color: #ff771f;
			font-size: 19px;
			font-weight: 600;
			cursor: pointer;
		}

		.sum {
			font-size: 42px;

			.azn {
				color: #ff691c;
			}
		}
		.time {
			font-size: 1rem;
			.timeNumber {
				margin-left: 0.5rem;
				color: #ff771f;
			}
		}
	}

	.setInner {
		border: 1px solid #ededed;
		font-size: 19px;
		font-weight: bold;
		padding: 20px 0;

		.imgContainer {
			padding: 0 20px !important;
		}
	}
	.edit {
		i {
			transition: 0.3s ease-in-out;
		}

		&:hover {
			i {
				color: #c04f08 !important;
			}
		}
	}

	.bell {
		@media (max-width: 768px) {
			.redact {
				display: none;
			}
		}

		.edit {
			i {
				transition: 0.3s ease-in-out;
			}

			&:hover {
				i {
					color: #c04f08 !important;
				}
			}
		}
		.notif {
			i {
				transition: 0.3s ease-in-out;
			}

			&:hover {
				i {
					color: #c04f08 !important;
				}
			}
		}

		.redact {
			color: black;
		}
	}

	.notif {
		color: black;
	}

	.addPhoneContainer {
		@media (max-width: 768px) {
			margin-left: 0 !important;
		}
	}

	.addPhone {
		border: 1px solid #6c757d;
		border-radius: 5px;
		text-align: center;
		display: flex;
		justify-content: center;
		cursor: pointer;
		width: 12rem;
		color: black;

		i {
			margin-top: 0.45rem;
			margin-right: 0.5rem;
			font-size: 0.8rem;
		}
	}

	.backToAccaunt {
		padding: 20px 10px;
		cursor: pointer;
		i {
			margin-right: 10px;
		}
	}
	.subCategory {
		font-weight: bold;
		font-size: 24px;
	}

	.subCategory1 {
		font-weight: bold;
		font-size: 24px;
		color: whitesmoke;
		text-align: start;
	}
	.subscriptionSection {
		position: relative;
		.subscriptionPeriodInner {
			padding: 10px;
			.subscriptionPeriod {
				border: 1px solid #ededed;
				border-radius: 10px;
				padding: 20px;
				width: 100%;
				position: relative;
				min-height: 220px;
				overflow: hidden;
				background-color: #0e111a;
				display: flex;
				flex-direction: column;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				&:hover,
				&.subActive {
					transform: scale(1.02);
					box-shadow: inset 0 0 10px #f7711c;
					.subscriptionPeriodMonth {
						color: #f7711c66 !important;
						top: 0;
					}
				}
				.subscriptionPeriodOfferWrapper {
					position: absolute;
					top: 15px;
					left: 15px;
					background-color: red;
					padding: 2px 8px;
					height: 20px;
					border-radius: 10px;
					font-size: 13px;
					font-weight: bold;
					box-shadow: 1px 1px 4px #f7711c;
					transition: all 0.3s ease-in-out;
					&:hover {
						animation: vibrate-1 1.5s ease-in-out infinite both;
					}
					.subscriptionPeriodOffer {
						color: white;
					}
				}
				.subscriptionPeriodMonth {
					color: rgba(165, 161, 178, 0.4);
					position: absolute;
					top: -16px;
					right: 8px;
					font-size: 88px;
					line-height: 88px;
					font-weight: 900;
					font-style: normal;
					transition: all 0.3s ease-in-out;
					&.subActive {
						color: hsla(23, 93%, 54%, 0.6) !important;
						top: 0;
					}
				}
				.subscriptionPeriodPriceBlockPrice {
					color: white;
					padding-top: 56px;
					font-size: 28px;
					font-weight: bold;
					display: flex;
				}
				.subscriptionPeriodPriceBlockDuration {
					color: #ffe0cc82;
				}
				.subscriptionPeriodDescription {
					line-height: 1;
					color: #9f9f9f;
					margin-top: auto;
					&.ActiveTill {
						color: #f7711c;
					}
					.subscriptionPeriodDescriptionTotalPrice {
						color: #f7711c;
					}
				}
			}
		}
	}
}
.Purchased {
	.empty {
		margin: 2rem auto;
		color: black;
	}
}
.dark-theme {
	.account {
		color: whitesmoke;
	}

	.movies-list {
		.empty {
			color: #ffffff8a;
		}
	}

	.profileNotif {
		transition: 0.3s ease-in-out;

		&:hover {
			background-color: rgba(245, 245, 245, 0.07);
		}
	}
	.profileSertif {
		transition: 0.3s ease-in-out;

		&:hover {
			background-color: rgba(245, 245, 245, 0.07);
		}
	}
	.profileNameChange {
		color: whitesmoke !important;
	}

	.redact,
	.notif {
		color: whitesmoke !important;
	}

	

	.profileSettings {
		&:hover {
			background-color: rgba(245, 245, 245, 0.07) !important;
		}
	}

	.addPhone {
		color: whitesmoke !important;
	}

	.profileName {
		color: whitesmoke !important;
	}

	// .profileMail {
	//   color: whitesmoke !important;
	// }
	.profileSection2 {
		.pmc {
			color: whitesmoke !important;
		}
		.active {
			color: #ff771f !important;
		}
	}
	.Purchased {
		.empty {
			color: #ffffff8a;
		}
	}
}

@-webkit-keyframes vibrate-1 {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	20% {
		-webkit-transform: translate(-2px, 2px);
		transform: translate(-2px, 2px);
	}
	40% {
		-webkit-transform: translate(-2px, -2px);
		transform: translate(-2px, -2px);
	}
	60% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
	80% {
		-webkit-transform: translate(2px, -2px);
		transform: translate(2px, -2px);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}
@keyframes vibrate-1 {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	20% {
		-webkit-transform: translate(-2px, 2px);
		transform: translate(-2px, 2px);
	}
	40% {
		-webkit-transform: translate(-2px, -2px);
		transform: translate(-2px, -2px);
	}
	60% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
	80% {
		-webkit-transform: translate(2px, -2px);
		transform: translate(2px, -2px);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

// .promoInput {
//     border: 1px solid black;
//     background-color: transparent;
//     padding-left: 0.7rem !important;
//     border-radius: 5px;
//     padding: 0.4rem;
// }

.promoBtn {
    background-color: #f7711c;
    border-radius: 5px;
    border: none;
    // height: 2.5rem;
    font-size: 1rem;
    font-weight: bold;
	padding: 1rem;
    color: whitesmoke;
    transition: 0.3s ease-in-out;
    justify-content: center;
    align-items: center;
	text-align: center;
	cursor: pointer;
}

.activeSubscriptionPeriod1 {
	border: 1px solid #ededed;
	border-radius: 10px;
	padding: 20px !important;
	color: white;
	width: 100%;
	position: relative;
	overflow: hidden;
	background-color: #0e111a;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: all 0.3s ease-in-out;
	box-shadow: inset 0 0 10px #f7711c;

	.subscriptionPeriodMonth {
		position: absolute;
		top: 0;
		right: 8px;
		font-size: 88px;
		line-height: 88px;
		font-weight: 900;
		font-style: normal;
		transition: all 0.3s ease-in-out;
		color: #f7711c66 !important;
	}
}

.orange-text {
	color: #f7711c;
}

.subscriptionPeriod1 {
	border: 1px solid #ededed;
	border-radius: 10px;
	padding: 20px !important;
	color: white;
	width: 100%;
	position: relative;
	overflow: hidden;
	background-color: #0e111a;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover,
	&.subActive {
		transform: scale(1.02);
		box-shadow: inset 0 0 10px #f7711c;
		.subscriptionPeriodMonth {
			color: #f7711c66 !important;
			top: 0;
		}
	}
	.subscriptionPeriodOfferWrapper {
		position: absolute;
		top: 15px;
		left: 15px;
		background-color: red;
		padding: 2px 8px;
		height: 20px;
		border-radius: 10px;
		font-size: 13px;
		font-weight: bold;
		box-shadow: 1px 1px 4px #f7711c;
		transition: all 0.3s ease-in-out;
		&:hover {
			animation: vibrate-1 1.5s ease-in-out infinite both;
		}
		.subscriptionPeriodOffer {
			color: white;
		}
	}
	.subscriptionPeriodMonth {
		color: rgba(165, 161, 178, 0.4);
		position: absolute;
		top: -16px;
		right: 8px;
		font-size: 88px;
		line-height: 88px;
		font-weight: 900;
		font-style: normal;
		transition: all 0.3s ease-in-out;
		&.subActive {
			color: hsla(23, 93%, 54%, 0.6) !important;
			top: 0;
		}
	}
	.subscriptionPeriodPriceBlockPrice {
		color: white;
		padding-top: 56px;
		font-size: 28px;
		font-weight: bold;
		display: flex;
	}
	.subscriptionPeriodPriceBlockDuration {
		color: #ffe0cc82;
	}
	.subscriptionPeriodDescription {
		line-height: 1;
		color: #9f9f9f;
		margin-top: auto;
		&.ActiveTill {
			color: #f7711c;
		}
		.subscriptionPeriodDescriptionTotalPrice {
			color: #f7711c;
		}
	}
}

.promoContent {
	text-align: start;
}

.buttonContainer {
	display: flex;
	justify-content: flex-end;

	.activatePromo {
		background-color: #f7711c;
		border-radius: 5px;
		border: none;
		height: 2.5rem;
		font-size: 1rem;
		font-weight: bold;
		padding: 0 1rem;
		color: whitesmoke;
		transition: 0.3s ease-in-out;
		justify-content: center;
		align-items: center;

		&:hover {
			background-color: #c04f08;
		}
	}
}

.promoModal {
	z-index: 10000;

	.promoInput {
		border: none;
		border-bottom: 1.5px solid grey;
		background-color: transparent;
		color: white;
	}
	.promoModal-header {
		background-color: #171c32;
		color: white;
		border-bottom: none !important;

		.close {
			color: white !important;
		}
	}
	.promoModal-body {
		background-color: #171c32;
		color: white;
	}
}

@media (max-width: 992px) {
	.promoActivating {
		margin-left: 0.8rem !important;
	}
}

