.CategoryContainer {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  
  background-color: white;
  transition: 0.3s ease-in-out;
  .title {

    h2 {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 2rem;

      color: #0e111a;
      transition: .3s ease-in-out;
      @media (max-width: 576px) {
        font-size: 1.2rem;
        line-height: 3rem;
        margin: 0;
      }
    }
    .arrowLink {
      
      line-height: 24px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;

      transition: .3s ease-in-out;

      .view {
        color: #0e111a;
        margin-right: 8px;
        transition:  .3s ease-in-out;
      }
      .straightArrow {
        height: 10px;
      }

      svg {
        fill: #0e111a;
        transition: .3s ease-in-out;
      }

      &:hover {
        .view {
          color: #f7711c;
          margin-right: 14px;
        }
        svg {
          fill: #f7711c;
        }
      }

      
    }
  }
  
  .bottomLine {
    background-color: #0e111a;
    height: 1px;
    width: 100%;
    margin: 15px 0;
  }

  .slider {
    .slider-control-bottomcenter ul{
        top:25px !important;
    }
  }

}

.dark-theme {
  background-color: #0e111a;

  transition: 0.3s ease-in-out;
  .CategoryContainer {
    transition: 0.3s ease-in-out;
    background-color: #0e111a;
    .title {
      h2 {
        color: white;
      }
    }
    .arrowLink {
      .view {
        color: white;
      }

      svg {
        fill: white;
      }
    }
  }
}