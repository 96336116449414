.ContactForm {
	display: flex;
	justify-content: center;
	text-align: center;
	transition: ease-in-out 0.3s;

	.bigRow {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.title {
		font-size: 1.8rem;
		font-weight: bold;
		margin-bottom: 1rem;
	}

	.inputContainer {
		margin: 0.5rem;
		border: 1px solid grey;
		border-radius: 10px;
		text-align: left;
		padding: 0.5rem 1rem;
		background-color: rgb(238, 238, 238);
		transition: all 0.3s ease-in-out;
		&.wrong {
			border-color: #f70d1c !important;
		}
		&.correct {
			border-color: green !important;
		}
		&input:active {
			border: 2px solid #f7711c;
		}

		.inputTitle {
			font-size: 0.8rem;
			font-weight: bold;
			padding-left: 0.2rem;
			color: black;

			.required {
				color: red;
			}
		}
		.input {
			border: none;
			background-color: transparent;
			font-size: 1rem;
			width: 100%;
			&::placeholder {
				font-size: 1rem;
				color: rgb(99, 99, 99);
			}
		}
	}

	.textareaContainer {
		margin: 0.5rem;
		border: 1px solid grey;
		border-radius: 10px;
		text-align: left;
		padding: 0.5rem 1rem;
		transition: all 0.3s ease-in-out;
		&.wrong {
			border-color: #f70d1c !important;
		}
		&.correct {
			border-color: green !important;
		}
		.textareaTitle {
			font-size: 0.8rem;
			font-weight: bold;
			padding-left: 0.2rem;

			.required {
				color: red;
			}
		}
		.textarea {
			border: none;
			background-color: transparent;
			font-size: 1rem;
			width: 100%;
			min-height: 100px;
			margin-top: 10px;

			&::placeholder {
				font-size: 1rem;
			}
		}
	}

	.submitContainer {
		margin: 0.5rem;

		.submitButton {
			width: 100%;
			border: none;
			height: auto;
			padding: 1rem;
			border-radius: 10px;
			background-color: #f7711c;
			color: whitesmoke;
			font-weight: bold;
			font-size: 1.1rem;

			&:focus {
				outline: none;
			}

			&:hover {
				background-color: #bd5514;
			}
		}
	}
}

.dark-theme {
	.ContactForm {
		color: whitesmoke !important;

		.inputContainer {
			background-color: rgba(238, 238, 238, 0.2);

			.inputTitle {
				color: whitesmoke !important;
			}
			.input {
				color: rgb(225, 225, 225);

				&::placeholder {
					color: rgb(172, 172, 172);
				}
			}
		}

		.textareaContainer {
			.textarea {
				color: rgb(201, 201, 201);
				&::placeholder {
					color: rgb(201, 201, 201);
				}
			}
		}
	}
}
