.ContactUsAdress {
  margin-top: 4rem !important;
  
  .infoPart {
    width: 100%;

    & > div {
      background-color: #f7711c;
      border-radius: 0.25rem;
    }

    .getInTouch {
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 2rem;
      padding-left: 2rem;
      font-size: 2rem;

      .getInTouchIcon {
        margin-right: 1rem;
      }

      .getInTouchTitle {
        font-weight: 600;
      }
    }

    .infoPartContent {
      color: white;
      margin-top: 1rem;
      width: 100%;
      padding-left: 2rem;
      padding-right: 1rem;
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      // margin-top: 2rem;
      height: 55%;

      .adressContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 2rem;
        color: white;
        font-size: 1rem;

        .adressContainerIconContainer {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          .adressContainerIcon {
            color: #f7711c;
            font-size: 20px;
          }
        }

        .adressTitle {
          font-size: 0.85rem;
          // padding-left: 0.9rem;
        }
      }

      .mailContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 2rem;
        color: white;
        font-size: 1rem;

        .mailContainerIconContainer {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          .mailContainerIcon {
            color: #f7711c;
            font-size: 20px;
          }
        }

        .mailTitle {
          font-size: 0.85rem;
          // padding-left: 0.9rem;
        }
      }

      .phoneContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 2rem;
        color: white;
        font-size: 1rem;

        .phoneContainerIconContainer {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          .phoneContainerIcon {
            color: #f7711c;
            font-size: 20px;
          }
        }

        .phoneTitle {
          font-size: 0.85rem;

          .phone {
            text-decoration: none;
            color: whitesmoke;
          }

        }
      }
    }
  }

  @media (max-width: 991px) {
    .ContactUsAdress {
      .infoPart {
        width: 100%;
        height: 15rem;

        & > div {
          background-color: #f7711c;
          border-radius: 0.25rem;
          margin-top: 2rem;
        }
      }

      .getInTouch {
        color: white;
        width: 30%;
        display: flex;
        flex-direction: row;
        align-items: flex-start !important;
        justify-content: left !important;
        padding-top: 1rem !important;
        font-size: 1.5rem !important;
        margin-left: 1rem !important;

        .getInTouchIcon {
          margin-right: 1.1rem !important;
          margin-top: 0.4rem;
        }

        .getInTouchTitle {
          font-weight: 600;
        }
      }

      .infoPartContent {
        color: white;
        margin-top: 1rem;
        width: 30% !important;
        padding-left: 2rem;
        font-size: 0.9rem;
      }
    }
  }
}
