.latest-blog {
  padding-top: 40px;
  transition: .3s ease-in-out;
  background-color: transparent;
  .title {

    h2 {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 24px;
      color: #0e111a;
      transition: .3s ease-in-out;
      @media (max-width: 576px) {
        font-size: 1.2rem;
        margin: 0;
      }
    }


    .arrowLink {
      line-height: 24px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;

      

      .view {
        color: #0e111a;
        margin-right: 8px;
        transition:  .25s ease-in-out;
      }
      .straightArrow {
        height: 10px;
      }

      svg {
        fill: #0e111a;
        transition: .25s ease-in-out;
      }

      &:hover {
        color: #f7711c;
        .view {
          margin-right: 14px;
        }
        svg {
          fill: #f7711c;
        }
      }

      
    }
  }

  .blog-box {
    position: relative;
    width: 100%;
    padding-top: 60%;

    .blog-content {
      position: absolute;
      padding: 5% 6%;
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;    

      color: white;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #0e111a;

      border-radius: 5px;
      box-shadow: 0 0 3px #0e111a;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      transition: all .3s ease-in-out;
      * {
          z-index: 10;
      }

      &:before {
          content: " ";
          display: block;
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          
          border-radius: 5px;
          background: linear-gradient(180deg, rgba(24, 24, 24, 0.55) 0%, #171717 100%);
          opacity: .5;
          z-index: 5;

          transition: all .3s ease-in-out;
      }

      .title {
        font-size: 1.1rem;
        font-weight: bold;

      }

      &:hover {
        text-decoration: none;
        color: #f7711c;
      }
    }
  }
  
}


.dark-theme {
  transition: 0.3s ease-in-out;
  .latest-blog {
    // background-color: #0e111a;
    .title {
      h2 {
        color: white;
      }
    }
    .arrowLink {
      .view {
        color: white;
      }

      svg {
        fill: white;
      }
    }
  }
}

/* .BlogContainer .BlogCardContainer{
  margin-top: 1rem;
}
.BlogContainer .title {
    font-size: 24px;
    line-height: 24px;
}
.BlogContainer .bottomLine {
  margin-top: 0.5rem;
  border-bottom: 1px solid rgb(232,232,232);
}
.BlogContainer .arrowLink{
    position: absolute;
    bottom: 0;
    right: 10%;
    display: flex;
    flex-direction: row;
    line-height: 8px;
    cursor: pointer;
}

.BlogContainer .view {
    display: inline-block; 
    margin-right: 5px;   
}

.BlogContainer .straightArrow {
    display: inline-block;
    height: 8px;
}

.BlogContainer .viewLink {
    font-size: 12px;
}


@media (max-width: 767px) {
    .BlogCardContainer:last-child {
      display: none;
    }
  }
  
  @media (max-width: 575px) {
    .BlogCardContainer:last-child {
      display: block;
    }
  } */