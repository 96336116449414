.QuestionComponent {
    margin-top: 1rem;
    margin-bottom: 1rem;

    transition: .3s ease-in-out;

    .cardComponent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .cardHeader {
            width: 100%;
            font-weight: bold;
            font-size: 16px;
            border: 1px solid rgb(230,230,230);
            width: 100%;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-radius: 5px;

            .questionTitle {
                margin-left: 2rem;
            }

            .plus {
                margin-right: 1.5rem;
            }

            .minus {    
                margin-right: 1.5rem;
                color: #f7711c
            }
        }

        .collapseComponent {
            border: 1px solid rgb(230,230,230);
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            .show {
                display: flex !important;
            }

            .cardBody {
                margin: 1rem;
            }
        }
    }
}

.dark-theme {
    .QuestionComponent {
        color: whitesmoke;

        .answerContent {
            color:rgb(220, 220, 220) !important;
        }
    }
}