.casts-slider {
    padding: 40px 0 20px;

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 2rem;
        margin-bottom: 1rem;

        color: #0e111a;
  
        transition: .3s ease-in-out;
        @media (max-width: 576px) {
          font-size: 1.5rem;
          line-height: 3rem;
          margin: 0;
        }
    }
    
    .cast-box {
        
        width: 100%;
        padding-top: 150%; 
        position: relative;
        
        cursor: pointer;
        display: block;
        margin-bottom: .3rem;

        border-radius: 5px;
        .cast-content {
            position: absolute;
            padding: 15px 0;
            height: 100%;
            width: 100%;
            right: 0;
            top: 0;    

            color: white;

            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #0e111a;

            border: 0;
            box-shadow: 0 0 3px #0e111a;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 5px;

            transition: .3s ease-in-out;
            * {
                z-index: 10;
            }

            &:before {
                content: " ";
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                
                border-radius: 5px;
                background: linear-gradient(180deg, rgba(24, 24, 24, 0.55) 0%, #171717 100%);
                opacity: .5;
                z-index: 5;

                transition: all .3s ease-in-out;
            }
        }
    }

    .cast-title {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0;
        line-height: 1rem;
        transition: .3s ease-in-out;
    }

    .cast-position {
        line-height: 1rem;
        font-size: .9rem;
        transition: .3s ease-in-out;
    }
}

.dark-theme {
    .casts-slider {

        h2 {
            color: white;
        }
        
        .cast-title {
            color: white;
        }
    
        .cast-position {
            color: white;
        }
    }
}