.terms {
  margin-top: 2rem;

  transition: 0.3s ease-in-out;
  .termsTitle {
    font-weight: 600;
    margin-bottom: 0.6rem;
    font-size: 1.3rem;
  }

  .termsContent {
    font-weight: 600;
    font-size: 1.1rem;
  }

  .termsSubContent {
    margin-top: 0.5rem;
    white-space: pre-wrap;
    font-size: 1rem;
  }
}

.dark-theme {
    .terms {
      p {
        span{
          color: whitesmoke !important;
        }
      }
    }

    .termsTitle {
      font-weight: 600;
      margin-bottom: 0.6rem;
      font-size: 1.3rem;
      color: whitesmoke;
    }
}
