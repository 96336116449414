.MovieGrid {
  .filterContainer {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: flex-start;
    cursor: pointer;

    &.filterContainerSort {
      justify-content: flex-end;
    }

    .filter {
      width: 100px;
      height: 30px;
      border: 2px solid #f7711c;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      vertical-align: middle;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: #f7711c;
        color: #fff !important;
      }

      .filterTitle {
        font-size: 14px;
        padding-top: 2px;
      }
    }
  }

  .filtredGenre {
    border-bottom: 1px solid #00000017;
    margin-right: 10px;
    margin-bottom: 10px;
    line-height: 24px;
    font-size: 18px;
    display: inline-block;

    transition: .3s ease-in-out;

    &:hover {
      border-bottom: 1px solid black !important;

      .delCross {
        color: black;
      }
    }

    .delCross {
      cursor: pointer;
      margin-left: 0.5rem;
      color: #000000a6;
      position: relative;
      vertical-align: middle;
      bottom: 2px;
      transition: .3s ease-in-out;
    }
  }

  .loadMoreContainer {
    margin-bottom: 3rem;
    margin-top: 1rem;
    justify-content: center;
    display: flex;
  }

  .loadMore {
    background-color: #f7711c;
    border-radius: 5px;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-size: 24px;
    display: flex;
    align-items: center;

    .loadMoreCircle {
      font-size: 1.2rem;
      line-height: 1.3rem;
      height: 1rem;
      width: 1rem;
      color: white;
    }

    i {
      font-size: 1.2rem;
      line-height: 1.3rem;
      margin-right: 5px;
      color: white;
    }

    .loadMore_spinner {
      margin-right: 5px;
      display: inline-flex;
      justify-content: center;
      color: white;

      &>span {
        width: 1.3rem;
        height: 1.3rem;
      }
    }

    .loadMore_text {
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}

.dark-theme {
  .MovieGrid {
    .filter {
      color: whitesmoke;
    }

    .filterRow {
      color: whitesmoke;
    }

    .filtredGenre {
      border-bottom: 1px solid #f5f5f5b3;

      .delCross {
        color: #f5f5f5b3;
      }

      &:hover {
        border-bottom: 1px solid #f5f5f5 !important;

        .delCross {
          color: #f5f5f5 !important;
        }
      }
    }

    .empty {
      color: whitesmoke;
    }

    .loadMore {
      color: white;
    }
  }
}