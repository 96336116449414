.PrivacyContainer {
  transition: 0.4s ease-in-out;

  .subtitle {
    color: #010101;
    font-family: Nunito;
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0;
  }

  .text,
  p {
    color: black;
    font-size: 18px;
  }

  li {
    list-style: none;
  }
}

.dark-theme {
  .PrivacyContainer {
    transition: 0.3s ease-in-out;
    color: whitesmoke !important;

    .privacy {
      p {
        span {

          color: whitesmoke !important;
        }
      }
    }

    .subtitle {
      color: whitesmoke;
    }
    .title {
      color: whitesmoke;
    }
  }
  .text,
  p {
    color: rgb(196, 196, 196);
  }
  li {
    color: rgb(215, 215, 215);
  }
}
