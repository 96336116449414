.GenresList {
	position: relative;
	padding: 40px 0;
	background: #e7e7e8;
	transition: 0.3s ease-in-out;
	.genre-card {
		width: 100%;
		padding-top: 70%;
		position: relative;
		cursor: pointer;

		.card {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 100%;
			background-color: #0e111a;
			padding: 16px 8px;
			text-align: center;
			font-weight: bold;
			color: white;
			transition: 0.3s ease-in-out;
			svg {
				display: inline-block;
				fill: white;
				height: 2rem;
				transition: 0.3s ease-in-out;
			}
			.genre-slide-item {
				font-size: .9rem;
			}
			&:hover {
				text-decoration: none;
				color: #f7711c;
				svg {
					fill: #f7711c;
				}
			}
		}
	}
}

.dark-theme {
	.GenresList {
		background-color: #0e111a;
		.genre-card {
			.card {
				background-color: #394569b3;

				color: white;

				&:hover {
					color: #f7711c;
				}
			}

			svg {
				fill: white;
				transition: 0.3s ease-in-out;
				&:hover {
					fill: #f7711c;
				}
			}
		}
	}
}
