.trailer-box {
  border-radius: 5px;
  position: relative;
  width: 100%;
  padding-top: 52%;
  // overflow: hidden;

  .watchLaterIcon {
    cursor: pointer;
    position: absolute;
    top: 4%;
    right: 4%;
    z-index: 30;
    line-height: 1;
    border: 0;
    background: transparent;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    
    margin-right: 10px;
    display: flex;
    align-items: center;
    
    i {
      margin-right: 0.8rem;
      font-size: 1.5rem;
    }


    @media (max-width:569px) {
      margin-top: 0.5rem;
      font-size: 1rem;
      i {
        margin-right: 0.8rem;
        font-size: 1.1rem;
      }

    }
    @media (max-width:425px) {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      i {
        margin-right: 0.5rem;
        font-size: 0.9rem;
      }

    }
  }

  

  .play-button {
    color: white;
    padding: 12px 35px;
    background-color: #f7711c;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    @media (max-width: 568px) {
      height: 2rem;
      width: 10rem;
      font-size: 0.7rem;
      padding: 0px 12px;
      justify-content: center;
    }

    span {
      font-weight: bold;
    }

    svg {
      fill: white;
      height: 1.3rem;

      transition: 0.3s ease-in-out;
    }

    &:hover {
      text-decoration: none;
      color: #0e111a;

      svg {
        fill: #0e111a;
      }
    }
  }

  &:after {
    content: " ";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 5px;
    left: 0;
    top: 0;

    z-index: 10;
    background-color: #0e111a;
    opacity: 0.5;
  }

  .trailer-content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #0e111a;

    // padding-top: 40px;

    .watch-trailer {
      position: absolute;
      right: 4%;
      bottom: 4%;

      z-index: 20;
      display: flex;

      @media (max-width: 576px) {
        .trailerBtn {
          font-size: 1rem;

          svg {
            width: 1rem;
          }
        }
      }

      @media (max-width:425px) {
        .trailerBtn {
          font-size: 0.7rem;

          svg {
            margin-bottom: 0.2rem;
            width: 0.8rem;
          }
        }
      }

      @media (max-width:360px) {
        .trailerBtn{
          margin-right: -1rem;
        }
      }

      button {
        border: 0;
        background: transparent;
        color: white;
        font-weight: bold;
        font-size: 1.2rem;

        margin-right: 10px;
        display: flex;
        align-items: center;

        outline: none;

        svg {
          width: 1.3rem;
          margin-right: 0.8rem;
          fill: white;
        }
      }
    }
  }

  .jwplayer {
    .jw-aspect.jw-reset {
      padding-top: 52% !important;
    }

    &.jw-stretch-uniform .jw-media video {
      object-fit: cover !important;
      padding: 10px;
      background-color: #0e111a;
    }
  }
}

.dark-theme {
  .trailer-box {
    box-shadow: 0px 0px 16px -5px rgba(247, 113, 28, 1);
  }
}

.modal-dialog {
  .modal-content {

    .modal-header {
      h5 {
        font-weight: bold !important;
      }
    }

    .modal-body {
      padding: 1.5rem;
    }

  }
}

.dark-theme~div {
  .modal-dialog {
    .modal-content {
      background-color: #0e111a !important;
      color: whitesmoke;
      box-shadow: 0px 0px 16px -5px rgba(247, 113, 28, 1);

      .modal-body {
        padding: 1.5rem;
      }

      .modal-header {
        button {
          color: whitesmoke !important;
        }

        border-bottom: 1px solid #dee2e66b;
      }

      .modal-footer {
        border-top: 1px solid #dee2e66b;
      }
    }
  }
}