#header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 50;
	background: white;
	padding: 10px 0;
	box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 1);
	transition: 0.3s ease-in-out;
	.navbar {
		padding: 0;
		.theme-switcher {
			transform: scale(1.5);
			input,
			.custom-control-input {
				width: 0;
				&:active:not(:checked) ~ .custom-control-label::before,
				&:focus:not(:checked) ~ .custom-control-label::before {
					border-color: #f7711c;
				}
				&:checked ~ .custom-control-label:before {
					background-color: #0e111a;
					border: 1px solid white;
				}
				&:checked ~ .custom-control-label:after {
					background-color: rgb(14, 17, 26);
					border: 1px solid #f7711c;
				}
				&:not(:disabled):active ~ .custom-control-label::before {
					background-color: rgb(14, 17, 26);
				}
			}
			label,
			.custom-control-label {
				cursor: pointer;
				&:before {
					background-color: rgb(14, 17, 26);
					border: 1px solid #f7711c;
					box-shadow: none;
				}
				&:after {
					border: 1px solid rgb(14, 17, 26);
				}
			}
		}
		.nav-link {
			font-weight: bold;
			color: #0e111a;
			transition: 0.3s ease-in-out;
			cursor: pointer;
			&:hover {
				color: #f7711c !important;
			}
		}

		@media (min-width: 1035px) {
			.right-nav-mobile {
				display: none;
			}
			.navLinksMobile {
				display: none;
			}
			.searchLink {
				display: none !important;
			}
			.profil {
				display: none !important;
			}
		}
		@media (max-width: 1034px) {
			.navLinksMobile {
				overflow: hidden;
				height: 100%;
				height: 45px;

				.navNavLinksMobile {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					height: 100%;
					justify-content: space-evenly;

					.navLinkfirst {
						margin-right: 1rem;
						margin-left: 1rem;
					}
					.navLink {
						margin-right: 1rem;
					}
				}
			}
			.collapsing {
				transition: height 0.01s !important;
			}

			.subscription,
			.right-nav {
				display: none;
			}

			.collapse,
			.collapsing {
				background-color: rgba(0, 0, 0, 0.8);
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease-in-out;

				ul {
					list-style-type: none;
					padding: 0;
					margin: 0;
					position: absolute;
					left: 50%;
					top: 170px;
					transition: all 0.3s ease-in-out;
					transform: translateX(-50%);

					li {
						transform: translateY(50px);
						opacity: 0;

						&.breaker {
							& + .nav-item {
								display: inline-block !important;
							}

							display: block !important;
							height: 2px;
							background-color: #f7711c;
						}

						a {
							display: block;
							font-size: 2em;
							text-decoration: none;
							padding: 10px 0;
							text-align: center;
							color: #fff;
							font-weight: bold;
							transition: all 0.2s ease-in-out;
							text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

							&:hover {
								color: red !important;
							}
						}
					}
				}
			}

			.toggle-btn {
				display: block;
				position: fixed;
				z-index: 10;
				right: 15px;
				cursor: pointer;
				margin: 0;

				.bar {
					width: 30px;
					height: 2px;
					margin: 7px auto;
					background-color: #0e111a;
					transition: all 0.3s ease-in-out;
					box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);

					&:nth-child(2) {
						width: 20px;
						background-color: #f7711c;
					}
				}
			}

			#navbar-toggler:checked {
				& + label.toggle-btn {
					.bar {
						background-color: #f7711c;

						&:nth-child(2) {
							transform: translateX(50px);
							opacity: 0;
						}

						&:nth-child(1) {
							transform: translateY(10px) rotate(45deg);
						}

						&:nth-child(3) {
							transform: translateY(-8px) rotate(-45deg);
						}
					}
				}

				& ~ .collapse.show {
					opacity: 1;
					visibility: visible;

					ul {
						top: 90px;

						li {
							transform: translateY(0px);
							opacity: 1;

							@for $i from 1 through 10 {
								$t: $i * 0.1;
								&:nth-child(#{$i}) {
									transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) #{$t}s;
								}
							}
						}
					}
				}
			}
		}
		@media (max-width: 375px) {
			.navLinksMobile {
				display: none;
			}
		}
	}

	.logo {
		height: 100%;
		svg {
			height: 100%;
			width: 7rem;
		}
	}

	.subscription {
		background-color: #f7711c;
		border-radius: 5px;

		.nav-link {
			color: white;
			&:hover {
				color: #0e111a !important;
			}
		}
	}

	.right-nav {
		display: flex;
		align-items: center;
		> div {
			margin-left: 1.2rem;

			&:last-child {
				margin-left: 1.5rem;
			}
		}

		.search {
			svg {
				width: 32px;
				fill: #f7711c;
			}
		}

		.account {
			svg {
				width: 32px;
				fill: #f7711c;
			}
		}
	}

	.right-nav-mobile {
		display: flex;
		align-items: center;
		margin-right: 40px;
		> div {
			margin-left: 1.2rem;

			&:last-child {
				margin-left: 1.5rem;
			}
		}

		.search {
			svg {
				width: 32px;
				fill: #f7711c;
			}
		}

		.account {
			svg {
				width: 32px;
				fill: #f7711c;
			}
		}
	}
	.navbar-expand-md {
		flex-flow: row nowrap !important;
		justify-content: space-between !important;
	}
	@media (max-width: 1034px) {
		.navbar-expand-md .navbar-nav {
			flex-direction: column;
		}
	}
}

.dark-theme {
	#header {
		background-color: #0e111a;

		box-shadow: 0px 3px 10px -5px rgba(247, 113, 28, 0.3);

		.navbar {
			.nav-link {
				color: white;
			}

			.toggle-btn {
				.bar {
					background-color: white;
					&:nth-child(2) {
						background-color: #f7711c;
					}
				}
			}
		}
	}
}

.search-enter {
	opacity: 0;
	transform: scale(0.9);
}
.search-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}
.search-exit {
	opacity: 1;
}
.search-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}
