.SubscriptionStatus {
	border: 1px solid lightgrey;
	border-radius: 10px;
	margin-bottom: 1.5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	transition: ease-in-out 0.3s;

	@media (max-width: 767px) {
		margin-bottom: 3rem;
	}

	.title {
		font-size: 1.6rem;
		font-weight: bold;
	}

	.inputContainer {
		margin-top: 1rem;
		.emailInput {
			background-color: transparent;
			border: none;
			border-bottom: 1px solid #d3d3d359;
			width: 100%;
			padding: 5px 0;
			transition: all 0.3s ease-in-out;
			&.valid {
				border-bottom: 1px solid green;
			}
			&.inValid {
				border-bottom: 1px solid #f70d1c;
			}

			&::placeholder {
				font-size: 0.9rem;
				font-weight: 600;
			}
		}
	}

	.buttonContainer {
		margin-top: 1.5rem;
		.confirmButton {
			width: 100%;
			border: none;
			height: auto;
			padding: 0.7rem;
			border-radius: 10px;
			background-color: #f7711c;
			color: whitesmoke;
			font-weight: bold;
			font-size: 1.1rem;

			&:focus,
			:active {
				outline: none;
			}

			&:hover {
				background-color: #b5571d;
			}
		}
	}
}

.dark-theme {
	.emailInput {
		color: white;
	}

	.SubscriptionStatus {
		color: whitesmoke !important;
	}
}
