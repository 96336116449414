.BlogPageContainer {
	display: flex;
	.rowPart {
		width: 100%;
    margin-left: 0 !important;

		.blogGrid {
			margin-top: 2rem;
		}

		.blog-box {
			position: relative;
			width: 100%;
			padding-top: 60%;

			.blog-content {
				position: absolute;
				padding: 5% 6%;
				height: 100%;
				width: 100%;
				right: 0;
				top: 0;

				color: white;

				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				

				border-radius: 5px;
				box-shadow: 0 0 3px #0e111a;

				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				transition: all 0.3s ease-in-out;
				* {
					z-index: 10;
				}

				&:before {
					content: ' ';
					display: block;
					position: absolute;
					height: 100%;
					width: 100%;
					left: 0;
					top: 0;

					border-radius: 5px;
					background: linear-gradient(180deg, rgba(24, 24, 24, 0.55) 0%, #171717 100%);
					opacity: 0.5;
					z-index: 5;

					transition: all 0.3s ease-in-out;
				}

				.title {
					font-size: 1.1rem;
					font-weight: bold;
				}

				&:hover {
					text-decoration: none;
					color: #f7711c;
				}
			}
		}
	}
}

.dark-theme {
	.blog-box {
		box-shadow: 0px 0px 16px -5px rgba(247, 113, 28, 1);
	}
}
