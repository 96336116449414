.blogDetails {
  .plus {
    margin-right: 0.6rem;
    color: rgb(193, 28, 28);
    font-size: 0.7rem;
  }
  .blogCategories {


    .card-body {
      padding-top: 0;
      padding-bottom: 0;
    }
    .categoryLinks {

        border-bottom: 1px solid #d3d3d359;
        width: 100%;

        &:last-child {
          border-bottom: none;
        }
    }

    

    .btn,
    .btn-link {
      &:hover {
        text-decoration: none !important;
        box-shadow: none !important;
      }
      &:active {
        outline: none !important;
        box-shadow: none !important;
      }
      &:focus {
        outline: none !important;
        box-shadow: none !important;
        text-decoration: none;
      }
      button {
        i {
          margin-right: 2px;
        }
        span {
          font-weight: 600;
        }
        i,
        span {
          color: black;
          font-size: 18px;
          text-decoration: none;
        }
      }
    }
  }
  .tags {
    border-radius: 10px;
    padding: 20px;
    border: 1px solid lightgray;
    cursor: pointer;
    .tagsTitle {
      font-weight: 600;
    }

    .d-inline-block {
      padding: 5px;
      &:hover {
        cursor: pointer;
        background-color: #ff771f;
      }
      &:hover a {
        color: white;
      }
    }
  }
}

.dark-theme {
  .blogDetails {
    .card {
      background-color: transparent;
    }
    .cardTitle {
      i {
        color: whitesmoke !important;
      }
      span {
        color: whitesmoke !important;
      }
    }

    .card-body {
      ul {
        li {
          a {
            color: whitesmoke !important;
            
            &:hover {
              color: #ff771f !important;
            }
          }
        }
      }
      .pl-3 {
        span {
          color: whitesmoke !important;
        }
      }
    }

    .tags {
      color: whitesmoke;

      .tagsBody {
        a {
          color: rgb(201, 201, 201);
        }
      }
    }

    hr {
      border-color: whitesmoke;
    }
  }
}

// .BlogFilterContainer {
//     margin-top: 4rem !important;
//     width: 100%;
//     display: flex;
//     flex-direction: column;

//     .allCategories {
//         width: 100%;
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         font-size: 18px;
//         border: 1px solid rgb(232,232,232);
//         height: 50px;

//         .allCategoriesTitle {
//             width: 100%;
//             font-weight: bold;
//         }

//         .menuIcon {
//             margin-left: 50px;
//             margin-right: 8px;

//         }
//     }

//     .categoriesList {
//         width: 100%;
//         height: 290px;
//         display: flex;
//         flex-direction: column;
//         justify-content: space-evenly;
//         font-size: 14px;
//         border: 1px solid rgb(232,232,232);

//         .categoryItem {
//             margin-left: 60px !important;
//             cursor: pointer;
//         }
//     }

//     .moreCategories {
//         width: 100%;
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         font-size: 16px;
//         border: 1px solid rgb(232,232,232);
//         height: 60px;
//         border-radius: 0 0 10px 10px;

//         .moreCategoriesIcon {
//             color: rgb(203, 8, 8);
//             margin-right: 6px;
//             font-size: 10px;
//         }
//     }

//     .popularTagContainer {

//         .popularTag {
//             width: 100%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             font-size: 18px;
//             font-weight: bold;
//             border: 1px solid rgb(232,232,232);
//             height: 40px;
//             margin-top: 50px;
//         }

//         .popularTagList {
//             display: flex;

//             .popularTagListTitle {
//                 border-bottom: solid 1px rgb(232,232,232);
//                 padding: 6px;
//                 margin-left: 3px;
//                 margin-right: 3px;
//                 &:hover {
//                     border: 0;
//                     border-radius: 3px;
//                     background: #f7711c;
//                     color: white;
//                     cursor: pointer;
//                 }
//             }
//         }
//     }

// }
