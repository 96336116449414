.LoadingComponent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: #ffffff;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;

        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 5rem;
            #fountainG{
                position:relative;
                margin:auto;
            }
            
            .fountainG{
                position:absolute;
                top:0%;
                fill:rgb(0,0,0);
                animation-name:bounce_fountainG;
                    -o-animation-name:bounce_fountainG;
                    -ms-animation-name:bounce_fountainG;
                    -webkit-animation-name:bounce_fountainG;
                    -moz-animation-name:bounce_fountainG;
                animation-duration:1.5s;
                    -o-animation-duration:1.5s;
                    -ms-animation-duration:1.5s;
                    -webkit-animation-duration:1.5s;
                    -moz-animation-duration:1.5s;
                animation-iteration-count:infinite;
                    -o-animation-iteration-count:infinite;
                    -ms-animation-iteration-count:infinite;
                    -webkit-animation-iteration-count:infinite;
                    -moz-animation-iteration-count:infinite;
                animation-direction:normal;
                    -o-animation-direction:normal;
                    -ms-animation-direction:normal;
                    -webkit-animation-direction:normal;
                    -moz-animation-direction:normal;
                // transform:scale(.3);
                //     -o-transform:scale(.3);
                //     -ms-transform:scale(.3);
                //     -webkit-transform:scale(.3);
                //     -moz-transform:scale(.3);
            }
            .fountainGO{
                position:absolute;
                top:0%;
                fill:#f7711c;
                animation-name:bounce_fountainG;
                    -o-animation-name:bounce_fountainG;
                    -ms-animation-name:bounce_fountainG;
                    -webkit-animation-name:bounce_fountainG;
                    -moz-animation-name:bounce_fountainG;
                animation-duration:1.5s;
                    -o-animation-duration:1.5s;
                    -ms-animation-duration:1.5s;
                    -webkit-animation-duration:1.5s;
                    -moz-animation-duration:1.5s;
                animation-iteration-count:infinite;
                    -o-animation-iteration-count:infinite;
                    -ms-animation-iteration-count:infinite;
                    -webkit-animation-iteration-count:infinite;
                    -moz-animation-iteration-count:infinite;
                animation-direction:normal;
                    -o-animation-direction:normal;
                    -ms-animation-direction:normal;
                    -webkit-animation-direction:normal;
                    -moz-animation-direction:normal;
                // transform:scale(.3);
                //     -o-transform:scale(.3);
                //     -ms-transform:scale(.3);
                //     -webkit-transform:scale(.3);
                //     -moz-transform:scale(.3);
            }
            
            #fountainG_1{
                left:0;
                animation-delay:1s;
                    -o-animation-delay:1s;
                    -ms-animation-delay:1s;
                    -webkit-animation-delay:1s;
                    -moz-animation-delay:1s;
            }
            
            #fountainG_2{
                left:29px;
                animation-delay:1.1s;
                    -o-animation-delay:1.1s;
                    -ms-animation-delay:1.1s;
                    -webkit-animation-delay:1.1s;
                    -moz-animation-delay:1.1s;
            }
            
            #fountainG_3{
                left:88px;
                animation-delay:1.2s;
                    -o-animation-delay:1.2s;
                    -ms-animation-delay:1.2s;
                    -webkit-animation-delay:1.2s;
                    -moz-animation-delay:1.2s;
            }
            
            #fountainG_4{
                left:117px;
                animation-delay:1.3s;
                    -o-animation-delay:1.3s;
                    -ms-animation-delay:1.3s;
                    -webkit-animation-delay:1.3s;
                    -moz-animation-delay:1.3s;
            }
            
            #fountainG_5{
                left:146px;
                animation-delay:1.4s;
                    -o-animation-delay:1.4s;
                    -ms-animation-delay:1.4s;
                    -webkit-animation-delay:1.4s;
                    -moz-animation-delay:1.4s;
            }
            
            #fountainG_6{
                left:175px;
                animation-delay:1.5s;
                    -o-animation-delay:1.5s;
                    -ms-animation-delay:1.5s;
                    -webkit-animation-delay:1.5s;
                    -moz-animation-delay:1.5s;
            }
            
            #fountainG_7{
                left:205px;
                animation-delay:1.6s;
                    -o-animation-delay:1.6s;
                    -ms-animation-delay:1.6s;
                    -webkit-animation-delay:1.6s;
                    -moz-animation-delay:1.6s;
            }
            #fountainG_8{
                left:234px;
                animation-delay:1.7s;
                    -o-animation-delay:1.7s;
                    -ms-animation-delay:1.7s;
                    -webkit-animation-delay:1.7s;
                    -moz-animation-delay:1.7s;
            }
            #fountainG_9{
                left:263px;
                animation-delay:1.8s;
                    -o-animation-delay:1.8s;
                    -ms-animation-delay:1.8s;
                    -webkit-animation-delay:1.8s;
                    -moz-animation-delay:1.8s;
            }
            #fountainG_10{
                left:292px;
                animation-delay:1.9s;
                    -o-animation-delay:1.9s;
                    -ms-animation-delay:1.9s;
                    -webkit-animation-delay:1.9s;
                    -moz-animation-delay:1.9s;
            }
            #fountainG_11{
                left:292px;
                animation-delay:2s;
                    -o-animation-delay:2s;
                    -ms-animation-delay:2s;
                    -webkit-animation-delay:2s;
                    -moz-animation-delay:2s;
            }
        }
    }



            @keyframes bounce_fountainG{
                0%{
                opacity: 1;
                    // fill:rgb(255,255,255);
                }
            
                100%{
                opacity: 0.2;
                    // fill:rgb(0,0,0);
                }
            }
            
            @-o-keyframes bounce_fountainG{
                0%{
                opacity: 1;
                    // fill:rgb(255,255,255);
                }
            
                100%{
                opacity: 0.2;
                    // fill:rgb(0,0,0);
                }
            }
            
            @-ms-keyframes bounce_fountainG{
                0%{
                opacity: 1;
                    // fill:rgb(255,255,255);
                }
            
                100%{
                opacity: 0.2;
                    // fill:rgb(0,0,0);
                }
            }
            
            @-webkit-keyframes bounce_fountainG{
                0%{
                opacity: 1;
                    // fill:rgb(255,255,255);
                }
            
                100%{
                opacity: 0.2;
                    // fill:rgb(0,0,0);
                }
            }
            
            @-moz-keyframes bounce_fountainG{
                0%{
                opacity: 1;
                    // fill:rgb(255,255,255);
                }
            
                100%{
                opacity: 0.2;
                    // fill:rgb(0,0,0);
                }
            }


@media (max-width: 425px) {
    .LoadingComponent {
        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.5rem;
        }
    }
}

.dark-theme {
    .LoadingComponent {
        background-color: #0e111a;
        .fountainG {
            fill: whitesmoke;
        }
    }
}