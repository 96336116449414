.HotMovie {
    padding: 20px 0;

    transition: .3s ease-in-out;
    .hot-movie-banner {
        position: relative;
        padding-top: 40%;
        width: 100%;
        
        color: white;
        transition: .3s ease-in-out;
        .hot-movie {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
    
            z-index: 1;
    
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
    
            * {
                z-index: 10;
            }
    
            &:before {
                content: " ";
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                
                border-radius: 5px;
                background: linear-gradient(180deg, rgba(24, 24, 24, 0.35) 0%, #171717 150%);
                z-index: 5;
            }
    
            .meta {
                width: 100%;
                padding: 2.5% 4%;
                .title {
                
                    font-size: 2rem;
                    font-weight: bold;
                }
        
                .actions {
    
                    .watch-trailer {
                        color: white;
                        font-weight: bold;
                        background: transparent;
                        border: none;
                        outline: none;
    
                        transition: .3s ease-in-out;
                        &:hover {
                            text-decoration: none;
                            color: #f7711c;;
                        }
                    }
                    
                    .play-button {
                        color: white;
                        padding: 12px 35px;
                        background-color: #f7711c;
                        border-radius: 5px;
    
                        transition: .3s ease-in-out;
    
                        span {
                            font-weight: bold;
                        }
                        svg {
                            fill: white;
                            height: 1.5rem;
    
                            transition: .3s ease-in-out
                        }
    
                        &:hover {
                            text-decoration: none;
                            color:  #0e111a;
    
                            svg {
                                fill: #0e111a;
                            }
                        }
                    }
                }
            }
    
            @media(max-width: 576px) {
                
    
                .meta {
                    padding: 2.5% 4%;
    
                    .title {
                        font-size: 1rem;
                        line-height: 1rem;
                    }
    
                    .actions {
                        
                        .play-button {
                            font-size: .8rem;
                            padding: 5px 10px;
    
                        }
                    }
                
                }
            }
        }
    
        .hot-movie {
            .jwplayer {
                border-radius: 5px;
                
                .jw-aspect.jw-reset {
                    padding-top: 40% !important;
                }
        
                &.jw-stretch-uniform .jw-media video {
                    object-fit: cover !important;
                    padding: 10px;
                    background-color: #0e111a;
                
                }
            }
        }
    }
}

.dark-theme {
    .HotMovie {
        background: #0e111a;
        
        .hot-movie-banner {
            box-shadow: 0px 0px 16px -5px rgba(247,113,28,1);
            border-radius: 5px;        
        }
    }
}