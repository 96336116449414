
.PopularMovieSlider {
  background-color: #0e111a;
  height: 500px;
  
  @media (max-width: 768px) {
    height: 350px;
  }
  .slider,
  .slider .slider-frame,
  .slider .slider-frame .slider-list,
  .slider .slider-frame .slider-list .slider-slide {
    width: 100% !important;
    height: 100% !important;
  }
  .slider {
    .slider-control-bottomcenter,
    .slider-control-centerleft,
    .slider-control-centerright {
      display: none;
    }
     
    .paging-item {
      button {
        outline: none;
        opacity: 1 !important;
        svg {
          width: 12px;
          height: 12px;
          fill: white !important;
          circle {
            cx: 6px !important;
            cy: 6px !important;
            r: 5px !important;
            stroke: white !important;
            stroke-width: 1 !important;
          }
        }
      }
      &.active {
        svg {
          border: 1px solid white;
          border-radius: 50%;
          circle {
            cx: 5px !important;
            cy: 5px !important;
            r: 2px !important;
            stroke: white !important;
            stroke-width: 1 !important;
          }
        }
      }
    }
  }
  .movieTrailerSlide {
    height: 100%;
    width: 100%;
    color: white;
    
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding-bottom: 35px;
    
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      // background: -webkit-linear-gradient(top, rgba(24, 24, 24, 0.35) 0%, #171717 100%);
      // background: linear-gradient(180deg, rgba(24, 24, 24, 0.05) 0%, #171717 120%);
    }

    .movieTrailerName {
      font-size: 50px;
      line-height: 50px;
      font-weight: bold;

      @media (max-width: 767px) {
        font-size: 35px;
      }

      @media (max-width: 576px) {
        font-size: 20px;
        line-height: 40px;
      }
    }

    .movieTrailerButton {
      padding: 0px 40px;
      background-color: #f7711c;
      border-radius: 5px;
      display: inline-flex;
      justify-content: center;
      vertical-align: middle;
      cursor: pointer;
      
      a {
        color: #0e111a;

        &:hover {
          text-decoration: none;
        }
      }

      @media (max-width: 576px) {
        margin-left: auto;
        padding: 0px 20px;
      }

      div {
        line-height: 50px;
        font-weight: bold;

        @media (max-width: 576px) {
          line-height: 40px;
        }

        svg {
          height: 24px;
          margin: auto;
          fill: white;
        }
      }
    }
  }
}
