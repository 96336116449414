.serial-details {
    background: transparent;

    transition: .3s ease-in-out;
}
.serial-header {

    color: #0e111a;

    .serial-title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 2rem;
        padding-bottom: .5rem;
        display: flex;
        color: #0e111a;
        transition: .3s ease-in-out;

        span {
            margin-left: 1rem;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: inherit;
        }
    }

    .age{
        font-size: 1.2rem;
        padding: 4px 8px;
        font-weight: bold;
        background-color: #f7711c;
        border-radius: 5px;
        margin-left: 1rem;
        display: inline-block;
        transition: 0.3s ease-in-out;
    }

    .genre-list {
        list-style: none;
        margin: 0;
        padding: 0;
        .genre-item {
            display: inline-block;

            a {
                display: inline-block;
                color: white;
                font-size: .8rem;
                font-weight: bold;
                padding: 5px 10px;
                border-radius: 50px;
                background: #0e101ad6;
                margin-right: .5rem;
                margin-bottom: .5rem;

                transition: .3s ease-in-out;

                &:hover {
                    color: #f7711c;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 837px) {
        .title {
            font-size: 1.5rem;
        }
        .age{
            font-size: 0.8rem;
            margin-left: 0rem;
        }
    }
}

.SerialTrailer {

    .trailer-player {
        background-color: rgb(15, 71, 103);
        margin-bottom: 3rem;
    }
}

.serial-description {

    .poster-container {

        .poster-image {
            width: 100%;
            border-radius: 5px;
            box-shadow: 0 0 16px -5px #f7711c;
        }
    }

    .meta {
		div {
			color: black;
			font-size: 1.2rem;
			padding: 4px 8px;
			font-weight: bold;

			transition: 0.3s ease-in-out;
			.movieYear {
				color: #f7711c;
				margin-left: 0.5rem;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				&:hover {
					text-decoration: none;
				}
			}
		}

		.time {
			float: right;
			color: #f7711c;
		}

		.country {
			float: left;
		}

		.year {
			float: left;
		}
	}

	.clearfix {
		content: '';
		clear: both;

		.country {
			.countryName {
				margin-left: 0.5rem;
				transition: all 0.3s ease-in-out;
				cursor: pointer;
				color: #f7711c;
				&:hover {
					color: #f7711c;
				}
			}
		}

		@media (max-width: 725px) {
			.country {
				font-size: 1rem;
			}
			.year {
				font-size: 1rem;
			}
			.time {
				font-size: 1rem;
			}
		}
	}

	.description {
		padding: 8px;

		.show-more-button {
			display: flex;
			font-weight: bold;
			color: #f7711c;

			&:hover {
				text-decoration: none;
			}

			&:after {
				content: '\25be';
				color: #0e111a;
				font-size: 1.3rem;
				line-height: 1.3rem;
				margin: 0 5px;
				display: inline-block;

				transition: 0.3s ease-in-out;
			}
		}

		&.expanded {
			.show-more-button {
				&:after {
					transform: rotate(-180deg);
				}
			}
		}
	}
}

.SerialInfo {

    .info-container {
        border: 2px solid #f7711c;
        border-radius: 5px;
        padding: 1.5rem;

        .season-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .season-tabs {
                display: flex;
                border: none !important;
                border-bottom: 1px solid grey !important;

                &:hover {
                    border-bottom: 1px solid grey !important;
                }
            }
            
            .serial-container {
                display: flex;
                flex-wrap: wrap;
                margin: 1rem 0;

                .poster {
                    border-radius: 5px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    height: 6rem;

                    .orange-bkg {
                        width: 100%;
                        height: 100%;
                        background-color: #f7701c6c;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        cursor: pointer;

                        svg {
                            fill: #f7711c;
                            height: 40%;
                            margin-top: 25%;
                        }
                    }
                }
                .seria-info {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    
                    
                    .seria-title {
                        color: #f7711c;
                    }

                    .progress {
                        height: 0.6rem !important;
                        margin: 0 !important;
                    }
        
                    .progress-bar {
                        background-color: #f7711c;
                    }

                    .seria-duration {
                        font-size: 0.9rem;
                    }
                }
            }

            
            .season-links {
                
                a {
                    text-decoration: none;
                    color: black !important;
                    cursor: pointer;

                    &:hover, &.active {
                        color: #f7711c !important;
                        font-weight: bold;
                    }
                }

                a.active {
                    color: #f7711c !important;
                    font-weight: bold;
                }
            
            }
        }
    }
}








.dark-theme {
    transition: 0.3s ease-in-out;

    .serial-details {
        background-color: #0e111a;

        .serial-header {
            color: white !important;

            .serial-title {
                color: white !important;
            }
            
            .genre-list {
                .genre-item {
                    a {
                        background: #252a46d6;
                    }
                }
            }
        }
    } 

   
}