.FilterListSideBar {
	width: 100vw;
	height: 100vh;
	overflow-y: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 199;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	&.active {
		background-color: #091433d6;
	}
	.filterListSideBar {
		transition: all 0.3s ease-in-out;
		transform: translateX(100%);
		width: 40vw;
		height: 100vh;
		overflow-y: auto;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 200;
		background-color: white;
		padding-top: 15vh;
		font-weight: bold;
		&.active {
			transform: translateX(0);
		}
		h2 {
			color: #f7711c;
			font-weight: bold;
		}

		.closeCross {
			display: flex;
			position: absolute;
			right: 15%;
			top: 5%;
			font-size: 2rem;
			color: #f7711c;
			cursor: pointer;
		}

		.filtredGenre {
			font-weight: bold;
			line-height: 34px;
			font-size: 23px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&.active {
				color: #ff6300 !important;
			}
		}

		.col-sm-10 {
			padding-left: 0;
		}
	}
	.slider {
		-webkit-appearance: none;
		width: 100%;
		height: 5px;
		background: #dddde1;
		outline: none;
		opacity: 0.7;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;
	}

	.slider:hover {
		opacity: 1;
	}

	.thumb {
		top: 50%;
		width: 2rem;
		height: 0.8rem;
		border-radius: 0.8rem;
		background: #ff7b00;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.thumbCurrent {
		position: absolute;
		top: 100%;
		right: 50%;
		transform: translateX(50%);
		color: white;
	}
	.yearsFilterTrack-1 {
		background-color: #9999a6;
		height: 5px;
	}
	.custom-muted {
		font-size: 10px;
		color: #9999a6 !important;
	}
	.yearFilterHeader {
		color: #1b1a38;
	}
	.clearYearFilter {
		color: #000;
		text-decoration: underline;
		font-size: 16px;
		font-weight: normal;
		cursor: pointer;
	}
	.makeYearFilter {
		background-color: #f7711c;
		border-radius: 5px;
		cursor: pointer;
	}
}

@media (max-width: 800px) {
	.FilterListSideBar {
		width: 100vw;
		height: 100vh;
		overflow-y: hidden;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 199;
		background-color: #091433d6;
		.filterListSideBar {
			width: 80vw;
			height: 100vh;
			overflow-y: auto;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 200;
			background-color: white;
			padding-top: 30vh;
			padding-bottom: 30vh;
			font-weight: bold;
		}
	}
}

@media (max-width: 600px) {
	.FilterListSideBar {
		width: 100vw;
		height: 100vh;
		overflow-y: hidden;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 199;
		background-color: #091433d6;
		.filterListSideBar {
			width: 100vw;
			height: 100vh;
			overflow-y: auto;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 200;
			background-color: white;
			padding-top: 10vh;
			padding-bottom: 30vh;
			font-weight: bold;
		}

		.closeCross {
			display: flex;
			position: absolute;
			right: 15%;
			top: 5%;
			font-size: 2rem;
			color: #f7711c;
		}
	}
}

.dark-theme {
	.FilterListSideBar {
		.filterListSideBar {
			background-color: #252a46;
		}

		.filtredGenre {
			color: rgb(231, 231, 231);
		}
		.yearFilterHeader {
			color: #fff;
		}
		.yearsFilterTrack-1 {
			background-color: #f7711c;
		}
		.clearYearFilter {
			color: white;
		}
	}
}
