.movie-card {
	width: 100%;
	padding-top: 150%;
	position: relative;

	cursor: pointer;

	.card {
		position: absolute;
		padding: 15px 0;
		height: 100%;
		width: 100%;
		right: 0;
		top: 0;

		color: white;

		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #0e111a;

		border: 0;
		box-shadow: 0 0 3px #0e111a;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		* {
			z-index: 10;
		}

		&:before {
			content: ' ';
			display: block;
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;

			border-radius: 5px;
			background: linear-gradient(180deg, rgba(24, 24, 24, 0.55) 0%, #171717 100%);
			opacity: 0.5;
			z-index: 5;

			transition: all 0.3s ease-in-out;
		}

		.duration {
			display: flex;
			width: 45%;

			padding-left: 6%;

			background-color: #f7711c;
			border-top-right-radius: 25px;
			border-bottom-right-radius: 25px;

			opacity: 0;

			transition: 0.3s ease-in-out;
			.time {
				padding: 3px 0;
				font-size: 0.8rem;
				font-weight: bold;
			}
		}

		.title {
			overflow-wrap: initial;
			text-align: center;
			font-size: 1.1rem;
			font-weight: bold;
			padding-left: 6%;
			padding-right: 6%;
		}

		.play-button {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 40px;
			fill: #f7711c;

			opacity: 0;

			transition: 0.3s ease-in-out;
		}

		&:hover {
			text-decoration: none;

			&:before {
				opacity: 0.8;
			}

			.duration {
				opacity: 1;
			}

			.play-button {
				opacity: 1;
			}
		}
	}
}
.dark-theme {
	.card {
		background-color: #252a46d6;
	}
}
