.QuestionsContainer {
    width: 100%;

    .questions {
        flex-direction: column;
    }

    .title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 2rem;
        padding-bottom: .5rem;
        display: flex;
        color: #0e111a;
        transition: .3s ease-in-out;
    }

   
}

.dark-theme {
 
    .title {
        color: white;
    }
}